<template>
  <div class="container py-5 col-lg-6">
    <div class="input-group col-lg-12" v-if="!playerMetaData">
      <div class="input-group-prepend">
        <span class="input-group-text bg-white px-4 border-md border-right-0">
          <i class="fa fa-user text-muted"></i>
        </span>
      </div>
      <input
        v-model="playerId"
        @paste="onPaste"
        id="playerId"
        type="text"
        name="playerId"
        placeholder="Enter Player ID"
        class="form-control bg-white border-left-0 border-md"
      />
    </div>

    <div class="input-group col-lg-12" v-if="!playerMetaData">
      <div class="input-group-prepend">
        <span class="input-group-text bg-white px-4 border-md border-right-0">
          <i class="fa fa-user text-muted"></i>
        </span>
      </div>
      <input
        v-model="confirmPlayerId"
        @paste="onPaste"
        id="confirmPlayerId"
        type="text"
        name="confirmPlayerId"
        placeholder="Confirm Player ID"
        class="form-control bg-white border-left-0 border-md"
      />
    </div>

    <div class="input-group col-lg-12">
      <div class="input-group-prepend">
        <span class="input-group-text bg-white px-4 border-md border-right-0">
          <i class="fa fa-folder-open text-muted"></i>
        </span>
      </div>
      <select
        v-model="selectedPackage"
        id="selectedPackage"
        name="selectedPackage"
        class="form-control custom-select bg-white border-left-0 border-md"
      >
        <option :value="{}">Select Game Pack</option>
        <option
          v-for="packageDetails in packages"
          :key="packageDetails.id"
          :value="packageDetails"
        >
          {{ packageDetails.name }}
        </option>
      </select>
    </div>

    <div v-if="!selectedPackage.isFixedValue" class="input-group col-lg-12">
      <div class="input-group-prepend">
        <span class="input-group-text bg-white px-4 border-md border-right-0">
          <i class="fa fa-money text-muted"></i>
        </span>
      </div>
      <input
        v-model="amount"
        id="amount"
        type="number"
        name="amount"
        :placeholder="amountPlaceholderText"
        class="form-control bg-white border-left-0 border-md"
      />
    </div>

    <ActionButton
      :text="rechargeButtonText"
      @onclick="onSubmitClicked"
      class="mb-4"
    />
  </div>
</template>

<script>
import ActionButton from "./ActionButton";
export default {
  components: {
    ActionButton,
  },
  props: {
    packages: {
      type: Array,
    },
    metaData: {
      type: Object,
    },
    playerMetaData: {
      type: String,
      default: "",
    },
  },
  watch: {
    metaData: function (newVal, oldVal) {
      this.playerId = newVal.playerId;
      this.confirmPlayerId = newVal.playerId;
      this.amount = newVal.amount;
      if (newVal.amount === 0) {
        this.selectedPackage.isFixedValue = true;
      }
      this.selectedPackage = {};

      if (this.packages.length > 0) {
        let currentPackage = this.packages.find(function (packageElement) {
          return packageElement.id === newVal.packageId;
        });
        if (currentPackage) {
          this.selectedPackage = currentPackage;
        }
      }
    },
    playerMetaData: function (newVal, oldVal) {
      if (newVal) {
        this.playerId = newVal;
        this.confirmPlayerId = newVal;
      }
    },
  },
  data() {
    return {
      selectedPackage: {},
      packageArray: [],
      buttonText: "Recharge",
      playerId: this.metaData.playerId,
      confirmPlayerId: this.metaData.playerId,
      amount: this.metaData.amount,
      amountPlaceholder: "Enter amount",
    };
  },
  mounted() {
    let that = this;
    if (this.metaData && this.packages.length > 0) {
      let currentPackage = this.packages.find((packageElement) => {
        return packageElement.id === that.metaData.packageId;
      });
      if (currentPackage) this.selectedPackage = currentPackage;
    }
  },
  methods: {
    onSubmitClicked() {
      if (!this.selectedPackage.id) {
        alert("Please select a package");
        return;
      }
      if (!this.playerId) {
        alert("Please enter a Player ID");
        return;
      }
      if (!this.confirmPlayerId) {
        alert("Please re-enter the Player ID");
        return;
      }

      this.playerId = this.playerId.trim();
      this.confirmPlayerId = this.confirmPlayerId.trim();

      if (this.playerId !== this.confirmPlayerId) {
        alert("Player Ids dont match");
        return;
      }
      if (this.selectedPackage.isFixedValue) {
        this.amount = 0;
      } else {
        if (isNaN(this.amount)) {
          alert("Enter valid amount");
          return;
        }
        this.roundOff();
      }
      this.$emit(
        "onGrantRequest",
        this.selectedPackage,
        this.playerId,
        this.amount
      );
    },
    roundOff() {
      this.amount =
        Math.round((parseFloat(this.amount) + Number.EPSILON) * 100) / 100;
    },
    onPaste(event) {
      this.$swal.fire({
        icon: "warning",
        title: "Paste Not Allowed!",
      });
      event.preventDefault();
    },
  },
  computed: {
    rechargeButtonText: function () {
      if (this.selectedPackage.isFixedValue) return "Activate";
      return "Recharge";
    },
    amountPlaceholderText: function () {
      let placeholder = "Enter Amount";
      if (this.selectedPackage.isFixedValue) return placeholder;
      if (
        Object.prototype.hasOwnProperty.call(
          this.selectedPackage,
          "minCount"
        ) &&
        Object.prototype.hasOwnProperty.call(
          this.selectedPackage,
          "maxCount"
        ) &&
        Object.prototype.hasOwnProperty.call(this.selectedPackage, "name")
      ) {
        placeholder =
          "Enter " +
          this.selectedPackage.name +
          " (" +
          this.selectedPackage.minCount +
          " - " +
          this.selectedPackage.maxCount +
          ")";
      }
      return placeholder;
    },
  },
};
</script>