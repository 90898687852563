<!--<template>
  <div>
    <nav id="navbar" class="navbar">
      <ul>
        <li class="nav-link scrollto">
          <div style="color: white; font-size: 14px">
            Credits Left: {{ walletCredits }}
          </div>
        </li>
        <li class="nav-link scrollto" style="color: white; font-size: 14px">
          <router-link :to="{ name: 'reseller_rzp' }">Your Account</router-link>
        </li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>
  </div>
</template>
-->

<template>
  <div>
    <b-navbar toggleable="xl" type="dark" variant="dark">
      <b-navbar-brand>
        <router-link :to="{ name: 'home' }"
          ><img src="../assets/moonfrog-logo.png" alt="logo" width="100"
        /></router-link>
      </b-navbar-brand>
      <b-navbar-brand style="pointer-events: none">
        <p id="mfcHeader" class="p-class btn">MF Credits: {{ credits }}</p>
      </b-navbar-brand>
      <b-navbar-toggle
        style="padding: 0"
        target="nav-collapse"
      ></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-item style="pointer-events: none">
            <p class="p-class btn">Credits Left: {{ credits }}</p>
          </b-nav-item> -->
          <b-nav-item>
            <router-link class="p-class btn" :to="{ name: 'home' }"
              >Home</router-link
            >
          </b-nav-item>
          <b-nav-item>
            <router-link class="p-class btn" :to="{ name: 'myReferrals' }"
              >My Referrals</router-link
            >
          </b-nav-item>
          <b-nav-item v-if="!isRetailer">
            <router-link class="p-class btn" :to="{ name: 'myNetwork' }"
              >My Network</router-link
            >
          </b-nav-item>
          <b-nav-item>
            <router-link class="p-class btn" :to="{ name: 'reseller_rzp' }"
              >My Account</router-link
            >
          </b-nav-item>
          <b-nav-item @click="signOut"
            ><p class="p-class btn btn-danger">Sign Out</p></b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import apiConfig from "../config/api.config";
import * as SageConstants from "../config/SageConstants";

export default {
  name: "ResellerHeader",
  props: {
    walletCredits: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isRetailer: Boolean,
    };
  },
  created() {
    if (
      this.$store.state.account.userType ===
      SageConstants.userTypeConstants.RETAILER
    )
      this.isRetailer = true;
    else this.isRetailer = false;
  },
  methods: {
    signOut() {
      this.$stats.StatsController.getInstance(
        this.$store.state.account.email,
        apiConfig.gameId,
        apiConfig.installOS,
        process.env.VUE_APP_STATS_URL
      ).count("mfsage", 1, "logout");
      localStorage.removeItem("access_token");
      this.$store.commit("resetAccount");
      this.$router.push("/");
    },
  },
  computed: {
    credits: function () {
      if (!this.walletCredits) {
        return 0;
      }
      return this.walletCredits.toFixed(2);
    },
  },
};
</script>
<style scoped>
.p-class {
  font-size: 1rem;
  font-weight: 1000;
  line-height: 1.7;
  color: white;
  text-transform: uppercase;
  margin-bottom: 0%;
}

.p-class:hover {
  text-decoration: underline;
}

@media (max-width: 331px) {
  #mfcHeader {
    font-size: 0.435rem;
  }
}

@media (min-width: 332px) and (max-width: 349px) {
  #mfcHeader {
    font-size: 0.5rem;
  }
}

@media (min-width: 350px) and (max-width: 365px) {
  #mfcHeader {
    font-size: 0.6rem;
  }
}

@media (min-width: 366px) and (max-width: 383px) {
  #mfcHeader {
    font-size: 0.7rem;
  }
}

@media (min-width: 384px) and (max-width: 401px) {
  #mfcHeader {
    font-size: 0.8rem;
  }
}

@media (min-width: 402px) and (max-width: 417px) {
  #mfcHeader {
    font-size: 0.9rem;
  }
}
</style>