<template>
  <div>
    <div class="main-content">
      <div class="header" style="padding-bottom: 8rem !important">
        <ResellerHeader :walletCredits="credits" />
      </div>
      <!-- Page content -->
      <div class="container-fluid mt--7">
        <div class="row">
          <!-- Buy Package Card -->
          <div class="col-xl-4 order-xl-2 mb-xl-0">
            <div class="card card-profile shadow">
              <div class="card-body pt-0 pt-md-4">
                <!-- Heading -->
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        card-profile-stats
                        d-flex
                        justify-content-center
                        mt-md-0
                      "
                    >
                      <div>
                        <span class="heading">Buy Packs</span>
                        <span class="description">
                          Buy {{ player.gameName }} packs for PID:
                          {{ player.playerId }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <SellPackage
                  :packages="gamePackagesArr"
                  @onGrantRequest="onGrantRequest"
                  :metaData="sellPackageMeta"
                  :playerMetaData="playerMeta"
                  class="col-lg-9"
                />
              </div>
            </div>
          </div>

          <!-- Left side view -->
          <div class="col-xl-8 order-xl-1" style="padding-bottom: 2rem">
            <!-- Player Info -->
            <div class="card bg-secondary shadow mb-4">
              <div class="card-body">
                <form>
                  <div class="row">
                    <h6 class="heading-small text-muted mb-0 mr-5">
                      Player info
                    </h6>
                    <div v-if="!isLoading">
                      <v-chip :color="getStatusColor(player.status)" dark>
                        {{ player.statusText }}
                      </v-chip>
                    </div>
                  </div>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-6 mb-0">
                        <div class="form-group focused mb-0">
                          <label class="form-control-label" for="input-username"
                            >Player Id</label
                          >
                          <input
                            type="text"
                            id="input-username"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="player.playerId"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-0">
                        <div class="form-group focused mb-0">
                          <label class="form-control-label" for="input-email"
                            >Game Name</label
                          >
                          <input
                            type="email"
                            id="input-email"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="player.gameName"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-0">
                        <div class="form-group focused mb-0">
                          <label
                            class="form-control-label"
                            for="input-username"
                          >
                            MF Credits Used Through You
                          </label>
                          <input
                            type="text"
                            id="input-mfcredits"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="player.creditsBought"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-0">
                        <div class="form-group focused mb-0">
                          <label class="form-control-label" for="input-username"
                            >With you since</label
                          >
                          <input
                            type="text"
                            id="input-dob"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="player.installDate"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- Transaction Table -->
            <ResellerPlayerTransactions
              :isLoading="!rechargeTableLoaded"
              :transactions="playerRecharges"
              @pageChanged="rechargeTablePageChanged"
              :page="rechargeTablePage"
              :totalPages="rechargeTableTotalPages"
              title="Transactions"
              @download="downloadRechargeTransactions"
              @search="searchRechargeTransactions"
              @transactionClicked="onTransactionClick"
            />
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import "@/assets/css/reseller.css";
import ResellerHeader from "../components/ResellerHeader";
import ResellerPlayerTransactions from "@/components/ResellerPlayerTransactions";
import SellPackage from "@/components/SellPackage";
import * as client from "@/client/webclient";
import * as SageConstants from "../config/SageConstants";
import * as SageUtils from "@/utils/SageUtils";

export default {
  name: "myPlayer",
  components: {
    ResellerHeader,
    ResellerPlayerTransactions,
    SellPackage,
  },
  props: {},

  data() {
    return {
      credits: 0,
      isLoading: true,

      rechargeTableLoaded: false,
      playerRecharges: [],
      rechargeTablePage: 1,
      rechargeTableTotalPages: 0,

      player: {
        playerId: "",
        gameId: "",
        gameName: "",
        creditsBought: 0,
        installDate: "",
        status: false,
        statusText: "",
      },

      gamePackagesArr: [],
      sellPackageMeta: {},
    };
  },

  computed: {
    walletCredits: function () {
      if (!this.credits) {
        return 0;
      }
      return this.credits.toFixed(2);
    },
    playerMeta: function () {
      return this.player.playerId;
    },
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      try {
        const creditData = await client.getMoonfrogCredits(
          this.$store.state.account.email,
          this.$store.state.account.email
        );
        this.credits = creditData;

        this.player.gameName = this.$route.query.gameName;
        this.player.gameId = this.$route.query.gameId;
        this.player.playerId = this.$route.query.playerId;
        this.player.creditsBought = this.$route.query.creditsBought;
        this.player.installDate = this.$route.query.installDate;
        this.player.status = this.$route.query.status;
        this.player.statusText =
          this.player.status === "true"
            ? "Eligible for Referral Bonus for this Player"
            : "Recharge a Pack for this Player to get Referral Bonus";

        await this.getGamePackages();

        let dates = [
          SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
          SageUtils.getDateFromToday(0),
        ];
        let txnData = await client.getResellerPlayerTransactions(
          this.$store.state.account.email,
          this.rechargeTablePage,
          this.$store.state.account.email,
          this.getDateRangeObj(dates),
          this.player.gameId,
          this.player.playerId
        );
        this.playerRecharges = txnData.txns;
        this.rechargeTableTotalPages = txnData.totalPages;
        this.rechargeTableLoaded = true;
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    // async getGamePackages() {
    //   try {
    //     let data = await client.getGamePackages(
    //       parseInt(this.player.gameId),
    //       this.player.gameName,
    //       this.$store.state.account.email
    //     );
    //     data
    //       .filter(
    //         (gamePack) =>
    //           !(
    //             gamePack.gameId ===
    //               SageConstants.filterPackageConfig.TPG_GAME_ID &&
    //             gamePack.inventoryType ===
    //               SageConstants.filterPackageConfig.CHIPS_INVENTORY &&
    //             gamePack.isFixedValue &&
    //             gamePack.priceInMfCredits >
    //               SageConstants.filterPackageConfig.MF_CREDITS_PRICE
    //           )
    //       )
    //       .forEach((gamePack) => {
    //         this.gamePackagesArr.push(gamePack);
    //       });
    //   } catch (err) {
    //     this.isLoading = false;
    //     this.showConnectionErrorDialog(err);
    //   }
    // },

    async getGamePackages() {
      try {
        let apiResponse = await client.getPackagesForPID(
          this.player.playerId,
          parseInt(this.player.gameId),
          this.$store.state.account.email
        );
        if (!apiResponse.isValid) throw Error("Player ID is not valid.");
        this.gamePackagesArr = apiResponse.packages;
      } catch (err) {
        this.isLoading = false;
        //     this.showConnectionErrorDialog(err);
      }
    },

    onGrantRequest(pkgDetails, playerId, amount) {
      const gameId = pkgDetails.gameId;
      const pkgId = pkgDetails.pkgId;
      let packageDetails = pkgDetails;
      let initialPrice = pkgDetails.priceInMfCredits;
      const resellerCountryCode = this.$store.state.account.countryCode;

      this.isLoading = true;
      client
        .validatePid(
          packageDetails.gameId,
          playerId,
          this.$store.state.account.email
        )
        .then((data) => {
          this.isLoading = false;
          if (!data.isValid) {
            this.$swal.fire({
              title: "Error",
              icon: "error",
              text: "Invalid PID!",
            });
          } else if (
            (amount < pkgDetails.minCount || amount > pkgDetails.maxCount) &&
            !pkgDetails.isFixedValue
          ) {
            this.$swal.fire({
              title: "Error",
              icon: "error",
              text:
                "Enter value between " +
                pkgDetails.minCount +
                " and " +
                pkgDetails.maxCount,
            });
          } else {
            this.$swal.fire({
              title: "Are you sure?",
              html: this.getPlayerConfirmationText(
                data.playerName,
                playerId,
                packageDetails,
                amount
              ),
              icon: "warning",
              confirmButtonText: "Confirm",
              showCancelButton: true,
              showLoaderOnConfirm: true,
              allowOutsideClick: () => !this.$swal.isLoading(),
              preConfirm: async () => {
                this.$swal.getCancelButton().setAttribute("hidden", true);
                if (amount !== 0) {
                  packageDetails.priceInMfCredits =
                    amount * packageDetails.priceInMfCredits;
                }
                const priceReceived = Math.ceil(
                  SageUtils.getAmountInclusiveTaxes(
                    packageDetails.priceInMfCredits.toFixed(2),
                    resellerCountryCode,
                    this.$store.state.creditExchangeRate[resellerCountryCode]
                  )
                );
                return client
                  .grantPackage(
                    playerId,
                    packageDetails,
                    priceReceived,
                    amount === 0 ? 1 : amount,
                    this.$store.state.account.email
                  )
                  .then((data) => {
                    if (
                      data.status === SageConstants.paymentConstants.SUCCESS
                    ) {
                      this.$swal
                        .fire({
                          icon: "success",
                          text: "Purchase Success",
                          confirmButtonText: "OK",
                          showCancelButton: false,
                        })
                        .then(() => {
                          this.$router.go();
                        });
                    } else {
                      this.$swal
                        .fire({
                          icon: "warning",
                          text: "Purchase Pending/unsuccessful",
                          confirmButtonText: "OK",
                          showCancelButton: false,
                        })
                        .then(() => {
                          this.$router.go();
                        });
                    }
                  })
                  .catch((error) => {
                    packageDetails.priceInMfCredits = initialPrice;
                    this.showConnectionErrorDialog(error);
                  });
              },
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.showConnectionErrorDialog(error);
        });
    },

    getPlayerConfirmationText(name, pid, pkgDetails, amount) {
      const game = pkgDetails.gameName;
      let price = pkgDetails.priceInMfCredits;
      const type = pkgDetails.inventoryType;
      const resellerCountryCode = this.$store.state.account.countryCode;

      if (amount !== 0) {
        price = pkgDetails.priceInMfCredits * amount;
      }

      let numberOfUnits = amount;
      if (amount === 0) numberOfUnits = 1;

      const content = document.createElement("p");
      content.style.cssText = "word-break:break-all";

      content.innerHTML =
        "Please Confirm Recharge <br><br><b><u>Player Details:</u><b>";
      if (name) {
        content.innerHTML += "<br>Player Name: <b>" + name + "</b>";
      }
      content.innerHTML +=
        "<br>Player ID: <b>" +
        pid +
        "<br><br><u>Recharge Details:</u><br></b>Game: <b>" +
        game +
        "</b><br>Pack: ";
      if (!pkgDetails.isFixedValue)
        content.innerHTML += "<b>" + numberOfUnits + "</b> ";
      content.innerHTML +=
        "<b>" +
        pkgDetails.name +
        "</b><br>MRP: <b>" +
        SageUtils.getCurrency(resellerCountryCode) +
        " " +
        Math.ceil(
          SageUtils.getAmountInclusiveTaxes(
            price.toFixed(2),
            resellerCountryCode,
            this.$store.state.creditExchangeRate[resellerCountryCode]
          )
        ) +
        "</b><br>MF Credits: <b>" +
        price.toFixed(2) +
        "</b>";
      return content;
    },

    async onTransactionClick(data) {
      this.sellPackageMeta = {
        playerId: data.targetPid,
        amount: data.amount,
        confirmPlayerId: data.targetPid,
        packageId: data.packageId,
      };
    },

    async rechargeTablePageChanged(queryData) {
      this.isLoading = true;
      this.rechargeTableLoaded = false;
      this.rechargeTablePage = queryData.pageNo;
      try {
        let txnData = await client.getResellerPlayerTransactions(
          this.$store.state.account.email,
          this.rechargeTablePage,
          this.$store.state.account.email,
          this.getDateRangeObj(queryData.dates),
          this.player.gameId,
          this.player.playerId
        );
        this.playerRecharges = txnData.txns;
        this.rechargeTableTotalPages = txnData.totalPages;
        this.rechargeTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async searchRechargeTransactions(dates) {
      this.isLoading = true;
      this.rechargeTableLoaded = false;
      this.rechargeTablePage = 1;
      try {
        let txnData = await client.getResellerPlayerTransactions(
          this.$store.state.account.email,
          this.rechargeTablePage,
          this.$store.state.account.email,
          this.getDateRangeObj(dates),
          this.player.gameId,
          this.player.playerId
        );
        this.playerRecharges = txnData.txns;
        this.rechargeTableTotalPages = txnData.totalPages;
        this.rechargeTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async downloadRechargeTransactions(dateRange) {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          this.$store.state.account.email,
          this.$store.state.account.email,
          "recharge",
          this.getDateRangeObj(dateRange),
          this.player.gameId,
          this.player.playerId
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    getStatusColor(status) {
      return status === "true"
        ? SageConstants.colors.ACTIVE
        : SageConstants.colors.PENDING;
    },

    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },

    getDateRangeObj(dateRange) {
      if (!dateRange) return {};
      if (dateRange.length === 1)
        return { from: dateRange[0], to: dateRange[0] };
      else if (dateRange.length === 2)
        return { from: dateRange[0], to: dateRange[1] };
      else return {};
    },
  },
};
</script>